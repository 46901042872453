var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"multi-col-validation mt-6"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.t('sports.name'),"dense":"","outlined":"","readonly":_vm.option===2,"rules":[_vm.required]},model:{value:(_vm.dataComputed.name),callback:function ($$v) {_vm.$set(_vm.dataComputed, "name", $$v)},expression:"dataComputed.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.t('sports.max_score_diff'),"dense":"","outlined":"","readonly":_vm.option===2,"rules":[_vm.required, _vm.minNumber(_vm.dataComputed.smsd, 1), _vm.regexValidator(_vm.dataComputed.smsd, '^[0-9]{0,99}(\.?[0-9]{0,2})?$', 'Incorrect format')]},on:{"input":function (e) {
              if (!_vm.charUnique(e, '.') || _vm.regexValidator(_vm.dataComputed.smsd, '^[0-9]{0,99}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                _vm.dataComputed.smsd = null
              }
            },"keypress":_vm.onlyNumberDot},model:{value:(_vm.dataComputed.smsd),callback:function ($$v) {_vm.$set(_vm.dataComputed, "smsd", $$v)},expression:"dataComputed.smsd"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.t('sports.score_k_factor'),"dense":"","outlined":"","readonly":_vm.option===2,"rules":[_vm.required, _vm.minNumber(_vm.dataComputed.score_k_factor, 1), _vm.regexValidator(_vm.dataComputed.score_k_factor, '^[0-9]{0,99}(\.?[0-9]{0,2})?$', 'Incorrect format')]},on:{"input":function (e) {
              if (!_vm.charUnique(e, '.') || _vm.regexValidator(_vm.dataComputed.score_k_factor, '^[0-9]{0,99}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
                _vm.dataComputed.score_k_factor = null
              }
            },"keypress":_vm.onlyNumberDot},model:{value:(_vm.dataComputed.score_k_factor),callback:function ($$v) {_vm.$set(_vm.dataComputed, "score_k_factor", $$v)},expression:"dataComputed.score_k_factor"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"label":_vm.t('sports.description'),"outlined":"","rows":"2","rules":[]},model:{value:(_vm.dataComputed.description),callback:function ($$v) {_vm.$set(_vm.dataComputed, "description", $$v)},expression:"dataComputed.description"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":_vm.t('status.status'),"items":_vm.statusOptions,"item-text":"text","item-value":"value","append-icon":_vm.option===2 ? null : _vm.icons.mdiMenuDown,"readonly":_vm.option===2,"rules":[_vm.required]},model:{value:(_vm.dataComputed.status),callback:function ($$v) {_vm.$set(_vm.dataComputed, "status", $$v)},expression:"dataComputed.status"}})],1),_c('options-buttom-tab',{attrs:{"tab-number":_vm.tab,"is-mode-save-edit":_vm.option !== 2,"no-save":false,"info-required":true,"no-cancel":_vm.option === 3,"no-arrow":true},on:{"onCancel":_vm.onCancelButton,"onNextTab":_vm.changeTab,"onSave":_vm.onSaveAction}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }